<template>
  <section class="wrapper-bloque wrapper-bloque-iconos" :id="id">

    <v-style :all="{data, content}">
    .bloque-{{data.bloque.ID}} {
      color: {{content.estilos.color_de_texto}};
    }
    .bloque-{{data.bloque.ID}} h1, .bloque-{{data.bloque.ID}} h2, .bloque-{{data.bloque.ID}} h3, .bloque-{{data.bloque.ID}} h4, .bloque-{{data.bloque.ID}} h5, .bloque-{{data.bloque.ID}} h6 {
      color: {{content.estilos.color_de_titulo}};
    }
    .bloque-{{data.bloque.ID}} .wrapper-popup.p-active,
    .bloque-{{ data.bloque.ID }} .wrapper-popup.p-active .popup{
      background: {{popup.color_popup_fondo}} !important;        
      color: {{popup.color_popup_texto}} !important;        
    }
    .bloque-{{data.bloque.ID}} .wrapper-popup.p-active .popup h1,
    .bloque-{{data.bloque.ID}} .wrapper-popup.p-active .popup h2,
    .bloque-{{data.bloque.ID}} .wrapper-popup.p-active .popup h3,
    .bloque-{{data.bloque.ID}} .wrapper-popup.p-active .popup h4,
    .bloque-{{data.bloque.ID}} .wrapper-popup.p-active .popup h5,
    .bloque-{{data.bloque.ID}} .wrapper-popup.p-active .popup h6{
      color: {{popup.color_popup_titulo}} !important;        
    }
    .bloque-{{data.bloque.ID}} .wrapper-popup.p-active .popup a{
      color: {{popup.color_popup_enlace}} !important;         
    }
    .bloque-{{data.bloque.ID}} .wrapper-popup.p-active .popup p{
      color: {{popup.color_popup_texto}} !important;          
    }
    .bloque-{{data.bloque.ID}} .wrapper-popup.p-active .popup .close-icon{
      color: {{popup.color_popup_texto}} !important;      
      background: none;
      top: 25px;     
    }
    .bloque-{{data.bloque.ID}} a,
    .bloque-{{data.bloque.ID}} .popup a
    {
      color: {{content.estilos.color_de_enlace}};
    }
    .bloque-{{data.bloque.ID}} .fa-stack{
      border-radius: {{content.redondeo_icono}}%;
    }
    </v-style>

    <div
      class="bloque-iconos"
      :class="`bloque-${data.bloque.ID}`"
    >
      <div class="container text-center">
    
        <h2
          class="bloque-title"
          v-if="data.titulo_bloque" 
          v-html="data.titulo_bloque"
          data-aos="fade"
          data-aos-once="true"
          data-aos-duration="1000"
        />
        
        <div 
        v-if="content.descripcion" 
        v-html="content.descripcion" 
        class="descripcion" 
        data-aos="fade"
        data-aos-once="true"
        data-aos-duration="1000"
        />
        
        <div class="row justify-content-around">
          <template
            v-for="(mi_icono, i) in content.iconos"
          >
            <icono
              :key="`icono_${i}`"
              :content="mi_icono" 
              :tipo="content.relacion_aspecto"
              :class="content.iconos_por_fila|columns"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1000"
            />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import Icono from './Icono.vue';
  import { bloques } from '../mixins/bloques'

  export default {
    mixins: [bloques],
    components: {
      Icono
    },
    data(){
      return {
        popup: {
                  color_popup_fondo: "",
                  color_popup_titulo: "",
                  color_popup_enlace: "",
                  color_popup_texto: ""
               },
      }
    },
    created(){
      // this.popup.color_popup_fondo = this.content.estilos.color_de_fondo
      // this.popup.color_popup_titulo = this.content.estilos.color_de_titulo
      // this.popup.color_popup_enlace = this.content.estilos.color_de_enlace
      // this.popup.color_popup_texto = this.content.estilos.color_de_texto
      
      if( this.content.popup ){
        if( this.content.popup.color_popup_fondo ){
          this.popup.color_popup_fondo = this.content.popup.color_popup_fondo
        } 
        if( this.content.popup.color_popup_titulo ) this.popup.color_popup_titulo = this.content.popup.color_popup_titulo
        if( this.content.popup.color_popup_enlace ) this.popup.color_popup_enlace = this.content.popup.color_popup_enlace
        if( this.content.popup.color_popup_texto ) this.popup.color_popup_texto = this.content.popup.color_popup_texto
      }      
        
    }
  }
</script>

<style lang="scss" scoped>
  .bloque-title {
    margin: 10px 0px 90px 0px;
  }
  .descripcion{
    margin-bottom: 60px; 
  }
</style>
