let bloques = {
  props: {
    id: String,
    type: String,
    content: Object,
    data: Object,
    estilos_generales: Object,
    autor: String,
    idioma: String,
    modalLeyes: Boolean
  }
}

export { bloques }
