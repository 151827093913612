var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length > 0 && _vm.items.length >= _vm.content.minimo_de_fotos_a_mostrar)?_c('section',{staticClass:"wrapper-bloque wrapper-bloque-imagen",attrs:{"id":_vm.id}},[_c('v-style',{attrs:{"all":{data: _vm.data, content: _vm.content, estilos_generales: _vm.estilos_generales}}},[_vm._v(" .bloque-"+_vm._s(_vm.data.bloque.ID)+" .swiper-button-prev, .bloque-"+_vm._s(_vm.data.bloque.ID)+" .swiper-button-next { color: "+_vm._s(_vm.content.estilos.color_de_titulo)+"; } .bloque-"+_vm._s(_vm.data.bloque.ID)+" .slide-element:hover:before { background-color: "+_vm._s(_vm.estilos_generales.bloque_principal.color_de_titulo)+"; color: "+_vm._s(_vm.content.estilos.color_de_texto)+"; } ")]),(_vm.content.mostrar_en_carrusel)?_c('v-style',{attrs:{"all":{data: _vm.data, content: _vm.content, estilos_generales: _vm.estilos_generales}}},[_vm._v(" .bloque-"+_vm._s(_vm.data.bloque.ID)+" .slide-element:hover:before { background-color: "+_vm._s(_vm.content.estilos.color_de_fondo)+"; color: "+_vm._s(_vm.content.estilos.color_de_texto)+"; } ")]):_vm._e(),_c('div',{staticClass:"bloque-imagen",class:("bloque-" + (_vm.data.bloque.ID))},[(_vm.data.titulo_bloque)?_c('h2',{staticClass:"bloque-title",attrs:{"data-aos":"fade","data-aos-once":"true","data-aos-duration":"1000"},domProps:{"innerHTML":_vm._s(_vm.data.titulo_bloque)}}):_vm._e(),(_vm.content.mostrar_en_carrusel)?_c('div',{staticClass:"wrapper-swipper",attrs:{"data-aos":"fade","data-aos-once":"true","data-aos-duration":"2000"}},[_c('swiper',{ref:("swiper_" + (_vm.data.bloque.ID)),staticClass:"swiper",attrs:{"options":Object.assign({}, _vm.swiperOptions,
          {slidesPerView: _vm.content.minimo_de_fotos_a_mostrar},
          {
            breakpoints: {
              1600: {
                slidesPerView: 5,
              },
              1200: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 1,
              },
              580: {
                slidesPerView: 1,
              },
              320: {
                slidesPerView: 1,
              }
            }
          })}},[_vm._l((_vm.items),function(slide,i){return [_c('swiper-slide',{key:("menu-item-" + i)},[_c('div',{staticClass:"slide-element",on:{"click":function($event){_vm.index = i}}},[_c('div',{staticClass:"swiper-lazy",style:(("background-image: url(" + (slide.src) + ")"))})])])]}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1):_c('div',{staticClass:"wrapper-imagenes",attrs:{"data-aos":"fade","data-aos-once":"true","data-aos-duration":"2000"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._l((_vm.items),function(slide,i){return [_c('div',{key:("imagen_" + i),staticClass:"col-xs-12 col-sm-12 col-lg-4 portfolio-item"},[_c('a',{staticClass:"slide-element",attrs:{"href":"#"},on:{"click":function($event){_vm.index = i}}},[_c('img',{attrs:{"loading":"lazy","intrinsicsize":"450x450","src":slide.src,"alt":("" + (slide.alt))}})])])]})],2)])]),_c('CoolLightBox',{attrs:{"items":_vm.items,"index":_vm.index,"effect":'fade'},on:{"close":function($event){_vm.index = null}}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }