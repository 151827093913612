export const fonts = [
  {
    name: 'Montserrat',
    folder: 'Montserrat',
    types: [
      {
        urls: [
          {
            url: 'fonts/Montserrat/Montserrat-Bold.woff2',
            format: 'woff2'
          }
        ],
        weight: '700',
        style: 'normal',
        display: 'swap'
      },
      {
        urls: [
          {
            url: 'fonts/Montserrat/Montserrat-Regular.woff2',
            format: 'woff2'
          }             
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'Open Sans',
    folder: 'OpenSans',
    types: [
      {
        urls: [
          {
            url: 'fonts/OpenSans/OpenSans-Bold.woff2',
            format: 'woff2'
          }
        ],
        weight: '700',
        style: 'normal',
        display: 'swap'
      },
      {
        urls: [
          {
            url: 'fonts/OpenSans/OpenSans-Regular.woff2',
            format: 'woff2'
          }             
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'Roboto',
    folder: 'RobotoSlab',
    types: [
      {
        urls: [
          {
            url: 'fonts/RobotoSlab/RobotoSlab-Bold.woff2',
            format: 'woff2'
          }
        ],
        weight: '700',
        style: 'normal',
        display: 'swap'
      },
      {
        urls: [
          {
            url: 'fonts/RobotoSlab/RobotoSlab-Regular.woff2',
            format: 'woff2'
          }             
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'BenchNine',
    folder: 'BenchNine',
    types: [
      {
        urls: [
          {
            url: 'fonts/BenchNine/BenchNine-Bold.woff2',
            format: 'woff2'
          }
        ],
        weight: '700',
        style: 'normal',
        display: 'swap'
      },
      {
        urls: [
          {
            url: 'fonts/BenchNine/BenchNine-Regular.woff2',
            format: 'woff2'
          }             
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'Droid Serif',
    folder: 'DroidSerif',
    types: [
      {
        urls: [
          {
            url: 'fonts/DroidSerif/DroidSerif-Bold.woff2',
            format: 'woff2'
          }
        ],
        weight: '700',
        style: 'normal',
        display: 'swap'
      },
      {
        urls: [
          {
            url: 'fonts/DroidSerif/DroidSerif-Regular.woff2',
            format: 'woff2'
          }             
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'Kaushan Script',
    folder: 'KaushanScript',
    types: [
      {
        urls: [
          {
            url: 'fonts/KaushanScript/KaushanScript-Regular.woff2',
            format: 'woff2'
          }             
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'Oswald',
    folder: 'Oswald',
    types: [
      {
        urls: [
          {
            url: 'fonts/Oswald/Oswald-Bold.woff2',
            format: 'woff2'
          }
        ],
        weight: '700',
        style: 'normal',
        display: 'swap'
      },
      {
        urls: [
          {
            url: 'fonts/Oswald/Oswald-Regular.woff2',
            format: 'woff2'
          }             
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'Amaranth',
    folder: 'Amaranth',
    types: [
      {
        urls: [
          {
            url: 'fonts/Amaranth/Amaranth-Bold.woff2',
            format: 'woff2'
          }
        ],
        weight: '700',
        style: 'normal',
        display: 'swap'
      },
      {
        urls: [
          {
            url: 'fonts/Amaranth/Amaranth-Regular.woff2',
            format: 'woff2'
          }             
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'Gentium',
    folder: 'Gentium',
    types: [
      {
        urls: [
          {
            url: 'fonts/Gentium/Gentium-Bold.woff2',
            format: 'woff2'
          }
        ],
        weight: '700',
        style: 'normal',
        display: 'swap'
      },
      {
        urls: [
          {
            url: 'fonts/Gentium/Gentium-Regular.woff2',
            format: 'woff2'
          }             
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'Signika',
    folder: 'Signika',
    types: [
      {
        urls: [
          {
            url: 'fonts/Signika/Signika-Bold.woff2',
            format: 'woff2'
          }
        ],
        weight: '700',
        style: 'normal',
        display: 'swap'
      },
      {
        urls: [
          {
            url: 'fonts/Signika/Signika-Regular.woff2',
            format: 'woff2'
          }             
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'Abril',
    folder: 'Abril',
    types: [
      {
        urls: [
          {
            url: 'fonts/Abril/Abril-Regular.woff2',
            format: 'woff2'
          }             
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'Alegreya',
    folder: 'Alegreya',
    types: [
      {
        urls: [
          {
            url: 'fonts/Alegreya/Alegreya-Bold.woff2',
            format: 'woff2'
          }
        ],
        weight: '700',
        style: 'normal',
        display: 'swap'
      },
      {
        urls: [
          {
            url: 'fonts/Alegreya/Alegreya-Regular.woff2',
            format: 'woff2'
          }             
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'Lato',
    folder: 'Lato',
    types: [
      {
        urls: [
          {
            url: 'fonts/Lato/Lato-Bold.woff2',
            format: 'woff2'
          }
        ],
        weight: '700',
        style: 'normal',
        display: 'swap'
      },
      {
        urls: [
          {
            url: 'fonts/Lato/Lato-Regular.woff2',
            format: 'woff2'
          }
        ],
        weight: '400',
        style: 'normal',
        display: 'swap'
      },
    ]
  },
  {
    name: 'Poppins',
    folder: 'Poppins',
    types: [
      {
        urls: [
          {
            url: 'fonts/Poppins/Poppins-Regular.woff2',
            format: 'woff2'
          }
        ],
        weight: '200',
        style: 'normal',
        display: 'swap'
      }
    ]
  },
]